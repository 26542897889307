import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import brushEffectGreen from "../../assets/images/svg/brush-effect-green.svg";
import brushEffectPuple from "../../assets/images/svg/brush-effect-purple.svg";
import { ReactComponent as ChevronImg } from "../../assets/images/svg/chevron-right-solid.svg";

export const StyledSmallCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 73 32px;
  width: 480px;
  height: 406px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: ${({ purpleCard, theme }) => (purpleCard ? `${theme.colors.purpleBoxBackgournd}` : `${theme.colors.greenBoxBackground}`)};
  border: ${({ purpleCard, theme }) => purpleCard ? `2px solid ${theme.colors.secondaryColor}` : `2px solid ${theme.colors.primaryColor}`};
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const StyledTypography = styled(Typography)`
  text-align: ${({ textStyle }) => (textStyle ? "left" : "center")};
  font-size: ${({ textStyle }) => (textStyle ? "20px" : "32px")};
  margin-left: 30px;
  margin-right: 30px;
  font-weight: ${({ titleStyle }) => (titleStyle ? "bold" : "light")};
`;

export const StyledButton = styled(Button)`
  width: 416px;
  height: 56px;
  padding: 16 204px;
  justify-content: center;
  background-color: ${({ purpleButton, theme }) => purpleButton ? theme.colors.secondaryColor : theme.colors.primaryColor};
  color: #FDFDFD;
  border-radius: 8px;
  text-transform: none;
  font-size: 20px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ purpleButton, theme }) => purpleButton ? theme.colors.secondaryColorHover : theme.colors.primaryColorHover};
  }

  & > svg {
    margin-left: 8px;
  }
`;

export const StyledBrushEffect = styled.span`
  background-image: url(${({ color }) => color ? brushEffectPuple : brushEffectGreen});
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 0;
  padding: 15px;
`;

export const CheckIcon = styled(ChevronImg)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.iconColor};
    fill: ${(props) => props?.theme?.colors?.iconColor};
  }
  width: 24px;
  height: 24px;
  display: inline-block;
`;