export const FormikParams = {
    username: "username",
    companyName: "companyName",
    primaryService: "primaryService",
    country: "country",
    city: "city",
    address: "address",
    phone: "phone",
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    password: "password",
    passwordConfirm: "passwordConfirm",
    sentEmailCode: "sentEmailCode",
    sentSMSCode: "sentSMSCode",
  };
  