import React from "react";
import PropTypes from "prop-types";
import { InputFieldContainer, InputFieldsContainer, SentCodeBtnContainer, SentCodeMessageContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import SentCodeField from "components/RegistrationContent/InputFields/SentCodeField";
import { userColors } from "themes/primaryTheme/primaryThemeColors";
import { FormikParams } from "constants/formikParams"
import { useTranslation } from "react-i18next";
import SendNewCodeButton from "components/RightAuthContent/Buttons/SendNewCodeButton/SendNewCodeButton";
import { useFormik } from "formik";
import { UserStepFourAtributes } from "initialValues/userRegInitialValues.js";
import { UserStepFourValidation } from "validations/registerUserValidation";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";

const UserStepFour = (props) => {
    const { t } = useTranslation();

    const handleSubmit = async () => {
      console.log(formik.values);
      //postNewClientRequest();

    }
    
    const onClickSendSMSCode = () => {
        console.log("send new SMS code");
    }
  
    const formik = useFormik({
      initialValues: UserStepFourAtributes,
      validationSchema: UserStepFourValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <SentCodeMessageContainer>
                        {t("code.sentPhoneMessage")}
                    </SentCodeMessageContainer>
                    <InputFieldContainer>
                    <SentCodeField
                        label={"code.labelSentPhoneCodeInput"}
                        name={FormikParams.sentSMSCode} 
                        formik={formik} 
                        required
                    />
                    </InputFieldContainer>
                    <SentCodeBtnContainer>
                    <SendNewCodeButton
                        onClick={onClickSendSMSCode}
                        colorPalet={userColors}
                    />
                    </SentCodeBtnContainer>
                </InputFieldsContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={userColors}
            />
        </>
    );
}

UserStepFour.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
};



export default UserStepFour;