import * as React from "react";
import { CardWrapper } from "../GlobalStyles/CardWrapper";
import PropTypes from "prop-types";
import LeftAuthContent from "components/LeftAuthContent/LeftAuthContent";
import RightAuthContent from "components/RightAuthContent/RightAuthContent";

const AuthContent = (props) => {
  return (
    <CardWrapper>
      <LeftAuthContent 
        type={props.type} 
        title={props.title}
        steps={props.steps}
      />
      <RightAuthContent 
        type={props.type} 
        label={props.label} 
        currentStep={props.currentStep} 
        onClickNextPageBtn={props.onClickNextPageBtn} 
        onClickBackBtn={props.onClickBackBtn} 
      />
    </CardWrapper>
  );
};

AuthContent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.string,
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
};

export default AuthContent;
