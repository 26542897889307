import React from 'react';
import { useTranslation } from 'react-i18next';
import OrDividerStyled from './OrDivider.styled';
import LongDash from '../LongDash/LongDash.styled';

const OrDivider = () => {
    const { t } = useTranslation();

    return (
        <OrDividerStyled>
            <LongDash/> {t("common.or")} <LongDash/>
        </OrDividerStyled>
    )
}

export default OrDivider;