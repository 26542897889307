import styled from "styled-components"

const OrDividerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 15px;
`;

export default OrDividerStyled;