import * as Yup from "yup";
import { t } from "i18next";

export const UserStepOneValidation = Yup.object().shape({
  firstName: Yup.string()
    .required(t("register.inputRequired")),
  lastName: Yup.string()
    .required(t("register.inputRequired")),
  email: Yup.string()
    .email(t("register.emailFormat"))
    .required(t("register.emailRequired")),
  password: Yup.string()
    .required(t("register.passwordRequired"))
    .min(8, t("register.passwordLength")),
  passwordConfirm: Yup.string()
    .required(t("register.passwordRequired"))
    .min(8, t("register.passwordLength")),
});

export const UserStepTwoValidation = Yup.object().shape({
  sentEmailCode: Yup.string()
    .required(t("register.inputRequired")),
});

export const UserStepThreeValidation = Yup.object().shape({
  address: Yup.string()
    .required(t("register.inputRequired")),
  phone: Yup.string()
    .required(t("register.inputRequired")),
});

export const UserStepFourValidation = Yup.object().shape({
  sentSMSCode: Yup.string()
    .required(t("register.inputRequired")),
});

