import Button from "components/Button/Button";
import styled from "styled-components";

export const SendNewCodeContainer = styled(Button)`
    background-color: ${props=> props.colorPalet.buttonColor};
    color: ${props => props?.theme?.colors?.iconColor};
    &:hover {
        background-color: ${props => props.colorPalet.buttonColorHover};
    }
    padding: 6px 32px 6px 32px;
    font-size: 14px;
    max-height:33px;
    border-radius:12px;
`