export default {
  app: {
    title: "Service Appointments",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  logo:{
    text: "Service Appointemnts",
  },
  common: {
    language: "Language",
    english: "English",
    serbian: "Serbian",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Password",
    labelCompanyName: "Company name",
    labelPrimaryService: "Primary service",
    labelCountry: "Country",
    labelCity: "City",
    labelAddress: "Address",
    labelPhone: "Phone",
    labelFirstName: "First name",
    labelLastName: "Last name",
    labelPasswordConfirmation: "Password confirmation",
    usernameInput: "Input username",
    email: "Input email address",
    passwordInput: "Input password",
    companyNameInput: "Input company name",
    primaryServiceSelect: "Choose primary service",
    countrySelect: "Choose country",
    citySelect: "Choose city",
    addressInput: "Input address",
    phoneInput: "Input phone",
    firstNameInput: "Input first name",
    lastNameInput: "Input last name",
    passwordConfirmationInput: "Confirm password",
    codeInput: "Input code",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    or: "Or",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
  },
  code: {
    sentEmailMessage: "We have sent you a validation code to the email you previously specified. Please check your email address and input the code  in order to finish the process of registration.",
    labelSentEmailCodeInput: "Input code sent to your email",
    sentPhoneMessage: "We have sent you a validation code to the email you previously specified. Please check your email address and input the code  in order to finish the process of registration.",
    labelSentPhoneCodeInput: "Input code sent as SMS message",
    sendNewCode: "Send new code",
  },
  register: {
    registerTitle: "Register",
    alreadyHaveAccount: "Already have account?",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    inputRequired: "Input is required.",
    progress: {
      title: {
        client: "Register as a service provider in a few steps",
        user: "Register as a service user in a few steps",
      },
      client: {
        steps: [
          "Company information",
          "Email and password",
          "Registration confirmation",
        ],
      },
      user: {
        steps: [
          "Email and password",
          "Registration confirmation 1",
          "Information",
          "Registration confirmation 2",
        ],
      },
    },
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address",
    password: "Please enter your password",
    logInTitle: "Login to Service Appointments",
    logIn: "Log In",
    signUp: "Sign Up",
    continueWithGoogle: "Continue with google",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  middlepage: {
    user: {
      title: "Register as a service <0>user</0>",
      text: "Do you want to quickly and easily book an appointment for the service you need?",
      button: "Register as a user",
    },
    client: {
      title: "Register as a service <0>provider</0>",
      text: "Are you providing services and need an easy way to schedule appointments?",
      button: "Register as a client",
    },
  },
  validationmessage: {
    client: {
      title: "You have successfully registered!",
      text: "You are ready for your first clients. Track appointments and manage appointments in a simple way.",
    },
    user: {
      title: "Your registration is complete!",
      text: "Now you can easily make appointments for the desired services",
    },
    button: "Close",
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
};
