import EmailField from "components/InputFields/EmailField";
import PasswordField from "components/InputFields/PasswordField";
import FirstNameField from "components/RegistrationContent/InputFields/FirstNameField";
import LastNameField from "components/RegistrationContent/InputFields/LastNameField";
import { InputFieldContainer, InputFieldsContainer, OrDividerGoogleBtnContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import { FormikParams } from "constants/formikParams"
import React from "react";
import PropTypes from "prop-types";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { ClientStepTwoAtributes } from "initialValues/clientRegInitialValues";
import { ClientStepTwoValidation } from "validations/registerClientValidation";
import { clientColors } from "themes/primaryTheme/primaryThemeColors";
import OrDivider from "components/OrDivider/OrDivider";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";

const ClientStepTwo = (props) => {
    const handleSubmit = async () => {
      console.log(formik.values);
      //postNewClientRequest();

      props.onClickNextPageBtn();
    }

    const handleGoogle = () => {
      
    }
  
    const formik = useFormik({
      initialValues: ClientStepTwoAtributes,
      validationSchema: ClientStepTwoValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <InputFieldContainer twoInputs>
                    <FirstNameField formik={formik} required/>
                    <LastNameField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer>
                    <EmailField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer twoInputs>
                    <PasswordField formik={formik} required/>
                    <PasswordField
                        label="common.labelPasswordConfirmation"
                        name={FormikParams.passwordConfirm}
                        placeholder="common.passwordConfirmationInput"
                        formik={formik} required/>
                    </InputFieldContainer>
                </InputFieldsContainer>
                <OrDividerGoogleBtnContainer>
                    <OrDivider/>
                    <GoogleButton onClick={handleGoogle}/>
                </OrDividerGoogleBtnContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={clientColors}
            />
        </>
    );
}

ClientStepTwo.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
};


export default ClientStepTwo;