import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomSelectFieldStyled, { DownArrowStyled } from "./CustomSelectField.styled";
import { MenuItem } from "@mui/material";

const CustomSelectField = (props) => {
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        props.passValue(event.target.value);
    };
    const [selectedValue, setSelectedValue] = useState('');
    return (
        <CustomSelectFieldStyled
            name={props.name}
            value={selectedValue}
            displayEmpty
            onChange={handleChange}
            width={props.width}
            renderValue={(selected) => {
                if (!selected) {
                  return <label>{props.placeholder}</label>;
                }
                return selected;
            }}
            IconComponent={DownArrowStyled}
        >
            {props.items.map(item => {
                return (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                );
            })}
        </CustomSelectFieldStyled>
    )
}


CustomSelectField.propTypes = {
    name: PropTypes.string,
    width: PropTypes.string,
    items: PropTypes.any,
    passValue: PropTypes.func,
    placeholder: PropTypes.string,
}

CustomSelectField.defaultProps = {
}

export default CustomSelectField;
