import React from "react"
import PropTypes from "prop-types";
import { GoogleButtonContainer } from "./GoogleButton.styled"
import { ReactComponent as GoogleImage } from "assets/images/svg/google-icon.svg"
import { useTranslation } from "react-i18next";

const GoogleButton = (props) => {
    const { t } = useTranslation();
    return (
        <GoogleButtonContainer
            value={t("login.continueWithGoogle")}
            startIcon={<GoogleImage />}
            onClick={props.onClick}
        >

        </GoogleButtonContainer>
    )
}

GoogleButton.propTypes = {
  onClick: PropTypes.func,
};

export default GoogleButton;