import React from "react"
import PropTypes from "prop-types";
import { NextButtonContainer } from "./NextButton.styled"
import { useTranslation } from "react-i18next";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow.svg"

export const NextButton = (props) => {
    const { t } = useTranslation();
    return (
        <NextButtonContainer
            onClick={props.onClick}
            colorPalet={props.colorPalet}
            value={t("common.next")}
            endIcon={<RightArrow/>}
        >
        </NextButtonContainer>
    )
}

NextButton.propTypes = {
  onClick: PropTypes.func,
  colorPalet: PropTypes.string,
};

export default NextButton;