import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field, FieldContainer } from "./CustomField.styled";

const CustomField = ({ error, type, name, label, value, handleChange }) => {
  return (
    <FieldContainer
      sx={{ display: "flex", flexDirection: "column", marginBottom: 1 }}
    >
      <Field
        sx={{
          "& fieldset": {
            border: error ? "1px solid #e90732" : "1px solid #858585",
            borderRadius: "8px",
          },
        }}
        type={type}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
      />
      <ErrorMessage variant="caption" sx={{ minHeight: "20px" }}>
        {error}
      </ErrorMessage>
    </FieldContainer>
  );
};

CustomField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};

export default CustomField;
