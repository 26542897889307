import { PAGES } from "constants/pages";
import _ from "lodash";

export const getBreadcrumbs = (location) => {
  const locationArray = location?.split("/").slice(1);
  const breadcrumbs = new Array(0);
  let pagesObject = PAGES;
  let pagesProperties = Object.keys(pagesObject);
  while (breadcrumbs?.length !== locationArray?.length) {
    let routeObject =
      pagesObject?.[
        pagesProperties?.find?.(
          (singlePageProperty) =>
            getPageNameFromRoute(pagesObject?.[singlePageProperty]?.route) ===
            locationArray?.[breadcrumbs?.length]
        )
      ];
    breadcrumbs?.push(routeObject?.title);
    pagesObject = routeObject?.nestedRoutes;
    pagesProperties = pagesObject != null && Object.keys(pagesObject);
  }
  return breadcrumbs;
};

const getPageNameFromRoute = (route) => {
  let routeArray = route?.split("/");
  if (routeArray?.[0] === "") return routeArray?.[1];
  else return routeArray?.[0];
};

export const getCurrentRoute = (path) => {
  let currentPage = Object.keys(PAGES).find((singleRoute) => {
    return compareRoutes(path, PAGES?.[singleRoute]?.route);
  });
  return PAGES?.[currentPage];
};

export const compareRoutes = (firstPath, secondPath) => {
  let firstPathArray = firstPath?.split?.("/");
  let secondPathArray = secondPath?.split?.("/");
  if (firstPathArray?.[firstPathArray?.length - 1] === "*")
    firstPathArray = firstPathArray?.slice(0, -1);
  if (secondPathArray?.[secondPathArray?.length - 1] === "*")
    secondPathArray = secondPathArray?.slice(0, -1);
  return _.isEqual(firstPathArray, secondPathArray);
};

export const isInRoute = (firstPath, secondPath) => {
  let firstPathArray = firstPath?.split?.("/");
  let secondPathArray = secondPath?.split?.("/");
  if (firstPathArray?.[firstPathArray?.length - 1] === "*")
    firstPathArray = firstPathArray?.slice(0, -1);
  if (secondPathArray?.[secondPathArray?.length - 1] === "*")
    secondPathArray = secondPathArray?.slice(0, -1);
  return secondPathArray?.every((singlePath, index) => {
    if (singlePath === firstPathArray?.[index]) return true;
    if (singlePath?.[0] === ":") return true;
    if (singlePath === "*") return true;
    return false;
  });
};