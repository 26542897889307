import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGES } from "constants/pages";
import loginValidation from "validations/loginValidation";
import loginInitialValues from "initialValues/loginInitialValues";
import EmailField from "components/InputFields/EmailField";
import PasswordField from "components/InputFields/PasswordField";
import OrDivider from "components/OrDivider/OrDivider";
import {
  RightAuthContainer,
  LowerContainer,
  ContentContainer,
  InputFieldsContainer,
  InputFieldContainer,
  ActionsContainer,
  LogTitleContainer,
  JustifiedRightItems,
  LogRegLinkStyled,
  FirstText,
  BottomTextContainer,
  ForgotPWLinkStyled,
  OrDividerGoogleBtnContainer,
  UpperContainerLogIn,
} from "components/RightAuthContent/RightAuthContent.styled";
import { LogInButton } from "./LogInButton/LogInButton";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";

const LogInContent = () => {
  const { t } = useTranslation();

  const handleGoogle = () => {};

  const handleSubmit = async (/*values*/) => {
    console.log("formik");
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <RightAuthContainer>
      <LogTitleContainer>{t("login.logInTitle")}</LogTitleContainer>
      <ContentContainer>
        <UpperContainerLogIn>
          <InputFieldsContainer>
            <InputFieldContainer>
              <EmailField formik={formik} required />
            </InputFieldContainer>
            <InputFieldContainer>
              <PasswordField formik={formik} required />
            </InputFieldContainer>
          </InputFieldsContainer>
          <JustifiedRightItems>
            <ForgotPWLinkStyled
              to={PAGES.FORGOT_PASSWORD.route}
              component={NavLink}>
              {t("forgotPassword.title") + "?"}
            </ForgotPWLinkStyled>
          </JustifiedRightItems>
          <ActionsContainer>
            <LogInButton onClick={formik.handleSubmit}/>
            <OrDividerGoogleBtnContainer>
              <OrDivider />
              <GoogleButton onClick={handleGoogle}/>
            </OrDividerGoogleBtnContainer>
          </ActionsContainer>
        </UpperContainerLogIn>
        <LowerContainer>
          <BottomTextContainer>
            <FirstText>{t("login.dontHaveAccount")}</FirstText>
            <LogRegLinkStyled
              to={PAGES.MIDDLEPAGE.route}
              component={NavLink}
              color={(props) => props?.theme?.colors?.primaryColor}>
              {t("register.registerTitle")}
            </LogRegLinkStyled>
          </BottomTextContainer>
        </LowerContainer>
      </ContentContainer>
    </RightAuthContainer>
  );
};

LogInContent.propTypes = {
  theme: PropTypes.any,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default LogInContent;
