import styled from "styled-components";
import { TextField } from "@mui/material";

const CustomTextFieldStyled = styled(TextField)`
    & .MuiOutlinedInput-root {
        & fieldset {
            border-radius: 12px;
        }
        &:hover fieldset {
            border-color: ${props=> props?.theme?.colors?.secondaryColor};
        }
        &.Mui-focused fieldset {
            border-color: ${props=> props?.theme?.colors?.secondaryColor};
        }
    }
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: ${props=> props?.theme?.colors?.errorColor};
    }
    & .MuiFormHelperText-root {
        font-size: 14px;
        margin-left: 24px;
        font-weight: 400;
        position: absolute;
        top: 100%;
    }
    margin-top: 5px;
    width: 100%;
`;

export default CustomTextFieldStyled;