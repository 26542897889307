import React from "react";
import PropTypes from "prop-types";
import {
  ProgressContainer,
  Title,
  Step,
  Circle,
  Line,
  Label,
  CheckIcon,
  LogImg,
  ContentContainer,
} from "./LeftAuthContent.styled";
import AuthType from "constants/authType";

const LeftAuthContent = (props) => {
  if (props.type === AuthType.LOGIN) {
    return <LogImg />;
  }

  return (
    <ProgressContainer>
      <Title>{props.title}</Title>
      {props.steps.map((step, index) => (
        <Step key={index}>
            <ContentContainer>
              <Circle type={props.type} completed={step.completed}>
                <span>{step.completed ? <CheckIcon /> : index + 1}</span>
              </Circle>
              <Label completed={step.completed}> {step.label} </Label>
            </ContentContainer>
            {index < props.steps.length - 1 && (
              <Line type={props.type} completed={step.completed} />
            )}
        </Step>
      ))}
    </ProgressContainer>
  );
};

LeftAuthContent.propTypes = {
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default LeftAuthContent;
