import React from 'react';
import PropTypes from 'prop-types';
import ButtonStyled from './Button.styled'
import { VARIANT } from '../../constants/buttonConstants';

const Button = (props) => {

    return (
        <ButtonStyled
            className={props?.className}
            name={props.name}
            variant={props.variant}
            disabled={props.disabled}
            hidden={props.hidden}
            maxWidth={props.maxWidth}
            maxHeight={props.maxHeight}
            type={props.type}
            startIcon={props.startIcon}
            endIcon={props.endIcon}
            onClick={props.onClick}
            fullWidth={props.fullWidth}
            bgColor = {props.bgColor}
            bgColorHover = {props.hoverColor}
            textColor={props.color}
            fontSize={props.fontSize}
            padding={props.padding}
            borderRadius={props.borderRadius}
            textTransform={props.textTransform}
        >{props.value}
        </ButtonStyled>
    )
}

Button.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    bgColor: PropTypes.string,
    bgColorHover: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    padding: PropTypes.string,
    borderRadius: PropTypes.string,
    className: PropTypes.string,
    textTransform: PropTypes.string,
    hoverColor: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    type: PropTypes.string,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
}

Button.defaultProps = {
    variant: VARIANT.TEXT,
    color: "black",
    fontSize: "20px",
    padding: "12px 32px 12px 32px",
    borderRadius: "12px",
    textTransform: "none",
}

export default Button;