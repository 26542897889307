import styled from "styled-components";
import { Select } from "@mui/material";
import { ReactComponent as DownArrow } from '../../../assets/images/svg/down-arrow.svg';

const CustomSelectFieldStyled = styled(Select)`
    & .MuiOutlinedInput-notchedOutline {
        border-radius: 12px;
    };
    width: 100%;
`;

export default CustomSelectFieldStyled;


export const DownArrowStyled = styled(DownArrow)`
    margin-top: 5px;
    margin-right: 20px;
`;