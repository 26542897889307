import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomSelectFieldStyled from "../../InputFields/CustomFields/CustomSelectField";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams"
import { Box } from "@mui/material";

const CountryField = (props) => {
    const { t } = useTranslation();
    const handleChange = (changedValue) => {
        props.formik.setFieldValue("country", changedValue);
    };
    const items = ["Frizer", "Mehanicar"];

    return (
        <Box>
            <CustomFieldLabel label={t(props.label)} required={props.required} />
            <CustomSelectFieldStyled
                name={props.name}
                formik={props.formik}
                placeholder={t(props.placeholder)}
                items={items}
                width={props.width}
                passValue={handleChange}
            />
        </Box>
    )
}

CountryField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    formik: PropTypes.any,
}

CountryField.defaultProps = {
    label: "common.labelCountry",
    name: FormikParams.country,
    placeholder: "common.countrySelect",
}

export default CountryField;
