import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AuthContent from "components/AuthContent/AuthContent";
import { useTranslation } from "react-i18next";
import AuthType from "constants/authType";

const RegisterPage = (props) => {
  const { t } = useTranslation();
  const title = t(`register.progress.title.${props.type}`);
  const [currentStep, setCurrentStep] = useState(0);

  const [steps, setSteps] = useState([
    { stepNumber: 0, label: t(`register.progress.${props.type}.steps.0`), completed: true },
    { stepNumber: 1, label: t(`register.progress.${props.type}.steps.1`), completed: false },
    { stepNumber: 2, label: t(`register.progress.${props.type}.steps.2`), completed: false },
  ]);

  useEffect(() => {
    if (props.type === AuthType.USER) {
      setSteps(prevSteps => {
        const stepExists = prevSteps.some(step => step.stepNumber === 3);
        if (stepExists) {
          return prevSteps;
        }
        return [
          ...prevSteps,
          { stepNumber: 3, label: t(`register.progress.${props.type}.steps.3`), completed: false },
        ];
      });
    }
  }, [props.type]);

  
  const onClickNextPageBtn = () => {
    setCurrentStep(oldValue => oldValue + 1);
    setSteps(oldSteps => oldSteps.map((step, index) => 
      index === currentStep + 1 ? { ...step, completed: true } : step
    ));
  }

  const onClickBackBtn = () => {
    setSteps(oldSteps => oldSteps.map((step, index) => 
      index === currentStep ? { ...step, completed: false } : step
    ));
    setCurrentStep(oldValue => oldValue - 1);
    
  }

  return (
    <AuthContent 
      type={props.type} 
      title={title} 
      steps={steps} 
      label={steps[currentStep].label}
      currentStep={currentStep}
      onClickNextPageBtn={onClickNextPageBtn}
      onClickBackBtn={onClickBackBtn} 
    />
  );
};

RegisterPage.propTypes = {
    theme: PropTypes.any,
    type: PropTypes.string.isRequired,
  };

export default RegisterPage;