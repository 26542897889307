import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import CustomTextField from "../../InputFields/CustomFields/CustomTextField";
import { FormikParams } from "constants/formikParams"
import { Box } from "@mui/material";

const PhoneField = (props) => {
    const { t } = useTranslation();

    return (
        <Box>
            <CustomFieldLabel label={t(props.label)} required={props.required} />    
            <CustomTextField
                type={props.type}
                name={props.name}
                placeholder={t(props.placeholder)}
                formik={props.formik}
                width={props.width}
            />
        </Box>
    )
}


PhoneField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    formik: PropTypes.any,
}

PhoneField.defaultProps = {
    label: "common.labelPhone",
    name: FormikParams.phone,
    placeholder: "common.phoneInput",
}

export default PhoneField;
