import React from "react";
import PropTypes from "prop-types";
import { InputFieldContainer, InputFieldsContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import CountryField from "components/RegistrationContent/InputFields/CountryField";
import CityField from "components/RegistrationContent/InputFields/CityField";
import AddressField from "components/RegistrationContent/InputFields/AddressField";
import PhoneField from "components/RegistrationContent/InputFields/PhoneField";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { userColors } from "themes/primaryTheme/primaryThemeColors";
import { UserStepThreeAtributes } from "initialValues/userRegInitialValues.js";
import { UserStepThreeValidation } from "validations/registerUserValidation";

const UserStepThree = (props) => {
    const handleSubmit = async () => {
      console.log(formik.values);
      //postNewClientRequest();

      props.onClickNextPageBtn();
    }
  
    const formik = useFormik({
      initialValues: UserStepThreeAtributes,
      validationSchema: UserStepThreeValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <InputFieldContainer twoInputs>
                    <CountryField formik={formik} required/>
                    <CityField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer twoInputs>
                    <AddressField formik={formik} required/>
                    <PhoneField formik={formik} required/>
                    </InputFieldContainer>
                </InputFieldsContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={userColors}
            />
        </>
    );
}

UserStepThree.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
};



export default UserStepThree;