import styled from "styled-components"
import { Button } from "@mui/material"

const ButtonStyled = styled(Button)`
    background-color: ${props => props?.bgColor};
    color: ${props => props?.textColor};
    font-size: ${props => props?.fontSize};
    padding: ${props => props?.padding};
    max-width: ${props => props?.maxWidth};
    max-height: ${props => props?.maxHeight};
    border-radius: ${props => props?.borderRadius};
    text-transform: ${props => props?.textTransform};
    &:hover {
        background-color: ${props => props?.bgColorHover};
    }
`;

export default ButtonStyled;