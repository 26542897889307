import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  StyledSmallCard,
  StyledButton,
  StyledTypography,
  StyledBrushEffect,
  CheckIcon,
} from "./MiddlepageContent.styled";
import { CardWrapper } from "../GlobalStyles/CardWrapper";
import { PAGES } from "constants/pages";

const MiddlePageContent = () => {
  const { t } = useTranslation();

  return (
    <CardWrapper>
        <StyledSmallCard purpleCard>
          <StyledTypography gutterBottom variant="h6" titleStyle>
            <Trans
              i18nKey={"middlepage.client.title"}
              components={[<StyledBrushEffect key={0} color />]}
            />
          </StyledTypography>
          <StyledTypography variant="body2" textStyle>
            {t("middlepage.client.text")}
          </StyledTypography>
          <NavLink to={PAGES.REGISTER_CLIENT.route}>
            <StyledButton variant="contained" size="small" purpleButton>
              {t("middlepage.client.button")} <CheckIcon />
            </StyledButton>
          </NavLink>
        </StyledSmallCard>
        <StyledSmallCard>
          <StyledTypography gutterBottom variant="h6" titleStyle>
            <Trans
              i18nKey={"middlepage.user.title"}
              components={[<StyledBrushEffect key={0} />]}
            />
          </StyledTypography>
          <StyledTypography variant="body2" textStyle>
            {t("middlepage.user.text")}
          </StyledTypography>
          <NavLink to={PAGES.REGISTER_USER.route}>
            <StyledButton variant="contained" size="small">
              {t("middlepage.user.button")} <CheckIcon />
            </StyledButton>
          </NavLink>
        </StyledSmallCard>
    </CardWrapper>
  );
};

export default MiddlePageContent;