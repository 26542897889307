import Button from "components/Button/Button";
import styled from "styled-components";

export const GoogleButtonContainer = styled(Button)`
    border-radius: 14.2px;
    padding: 8px 16px 8px 16px;
    max-width: 308px;
    font-size: 16px;
    background-color: #F4F4F4;
    &:hover {
        background-color: #F0F0F0;
    }
`