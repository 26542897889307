import styled, { css } from "styled-components";
import { Typography } from "@mui/material";

const CustomFieldLabelStyled = styled(Typography)`
    font-weight: bold;
    color: black;
    ${props => props?.required && css`
        &::after {
            content: "*";
        }
    `}
`;

export default CustomFieldLabelStyled;