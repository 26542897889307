import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
  max-width: fit-content;
  gap: 32px;
  //aspect-ratio: 1200 / 759;
  background-color: #FDFDFD;
  border-radius: 16px;
  padding: 24px;
  margin: 0 auto; 
  margin-top: calc((100vh - 72px - 759px) / 2);
`;