import React from "react";
import PropTypes from "prop-types";
import CustomTextFieldStyled from "./CustomTextField.styled";
import { IconButton, InputAdornment } from "@mui/material";
import TYPE from '../../../constants/inputTypes';

const CustomTextField = (props) => {
    
    
    return (
      <>
        <CustomTextFieldStyled
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            value={props.formik?.values?.[props?.name]}
            onChange={props.formik?.handleChange}
            onBlur={props.formik?.handleBlur}
            error={props.formik?.touched?.[props?.name] && Boolean(props.formik?.errors?.[props?.name])}
            helperText={props.formik?.touched?.[props?.name] && props.formik?.errors?.[props?.name]}
            width={props.width}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={props.endIconClick}
                      onMouseDown={props.endIconMouseDown}
                    >
                      {props.endIcon}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            
        >
        </CustomTextFieldStyled>
      </>
    )
}


CustomTextField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    formik: PropTypes.any,
    width: PropTypes.string,
    endIconClick: PropTypes.func,
    endIconMouseDown: PropTypes.func,
    endIcon: PropTypes.any,
}

CustomTextField.defaultProps = {
    type: TYPE.TEXT,
}

export default CustomTextField;
