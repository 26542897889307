export default {
  app: {
    title: "Service Appointments",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  logo:{
    text: "Service Appointemnts",
  },
  common: {
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Close",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelUsername: "Korisničko ime",
    labelEmail: "Email",
    labelPassword: "Šifra",
    labelCompanyName: "Naziv firme",
    labelPrimaryService: "Primarna delatnost",
    labelCountry: "Država",
    labelCity: "Grad",
    labelAddress: "Adresa",
    labelPhone: "Telefon",
    labelFirstName: "Ime",
    labelLastName: "Prezime",
    labelPasswordConfirmation: "Potvrda šifre",
    usernameInput: "Unesite korisničko ime",
    passwordInput: "Unesite šifru",
    email: "Unesite email",
    companyNameInput: "Unesite naziv firme",
    primaryServiceSelect: "Izaberite primarnu delatnost",
    countrySelect: "Izaberite državu",
    citySelect: "Izaberite grad",
    addressInput: "Unesite adresu",
    phoneInput: "Unesite telefon",
    firstNameInput: "Unesite ime",
    lastNameInput: "Unesite prezime",
    passwordConfirmationInput: "Potvrdite šifru",
    codeInput: "Unesite kod",
    next: "Dalje",
    nextPage: "Sledeća stranica",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdite",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    or: "ili",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} do {{end}}",
    },
    logout: "Izloguj se",
    seeMore: "Vidi još",
  },
  code: {
    sentEmailMessage: "Poslali smo Vam kod za potvrdu na email adresu koju ste naveli. Molimo Vas da proverite svoju email adresu i unesete kod kako biste završili proces registracije.",
    labelSentEmailCodeInput: "Unesite kod koji Vam je stigao na mail",
    sentPhoneMessage: "Poslali smo Vam kod za potvrdu na broj telefona kojiste naveli. Molimo vas da proverite telefon i unesete kod kako biste završili proces registracije.",
    labelSentPhoneCodeInput: "Unesite kod koji Vam je stigao u SMS poruci",
    sendNewCode: "Pošalji novi kod",
  },
  notifications: {
    title: "Obaveštenja",
  },
  pages: {
    home: "Početna",
    login: "Login",
    register: "Register",
    forgotPassword: "Zaboravljena lozinka",
    error: "Pogrešna stranica",
    notFound: "Nije pronađena stranica",
    profile: "Profilna stranica",
    settings: "Podešavanja",
    dashboard: "Komandna tabla",
  },
  register: {
    registerTitle: "Registrujte se",
    alreadyHaveAccount: "Već imate nalog?",
    usernameRequired: "Korisničko ime je obavezno.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordRequired: "A Password is required.",
    passwordConfirm: "Potvrdite šifru",
    inputRequired: "Polje je obavezno.",
    progress: {
      title: {
        client: "Registruj se kao pružalac usluga u par koraka",
        user: "Registruj se kao korisnik usluga u par koraka"
      },
      client: {
        steps: [
          "Informacije o firmi",
          "Email i lozinka",
          "Potvrda registracije"
        ]
      },
      user: {
        steps: [
          "Email i lozinka",
          "Potvrda registracije 1",
          "Informacije",
          "Potvrda registracije 2"
        ]
      },
    }
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Loš format email-a",
    emailRequired: "Email/korisničko ime je obavezno",
    noUsers: "Ne postoji korisnik",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Registrujte se",
    email: "Unesite svoj email",
    password: "Unesite šifru",
    logInTitle: "Ulogujte se na Service Appointments",
    logIn: "Ulogujte se",
    continueWithGoogle: "Nastavite preko google-a",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Zaboravili ste šifru?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Zaboravili ste šifru",
    label: "Pošaljite email",
    emailRequired: "Email je obavezan.",
    emailFormat: "Nevalidan format za email.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  date: {
    timespan: {
      yearsAgo: "Pre {{years}} godine",
      monthsAgo: "Pre {{months}} meseca",
      daysAgo: "Pre {{days}} dana",
      hoursAgo: "Pre {{hours}} sata",
      minutesAgo: "Pre {{minutes}} minuta",
      secondsAgo: "Pre {{seconds}} sekunde",
      now: "Upravo sada",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Linijski grafikon",
      pieChart: "Kružni grafikon",
      barChart: "Dijagram",
      radarChart: "Radarski grafikon",
    },
  },
  middlepage: {
    user: {
      title: "Registruj se kao <0>korisnik</0> usluga",
      text: "Želiš da na brz i jednostavan način zakažeš termin za uslugu koja ti je potrebna.",
      button: "Registruj se kao korisnik",
    },
    client: {
      title: "Registruj se kao <0>pružalac</0> usluga",
      text: "Baviš se pružanjem usluga i potreban ti je lak način da zakazuješ termine.",
      button: "Registruj se kao klijent",
    },
  },
  validationmessage: {
    client: {
      title: "Uspešno ste se registrovali!",
      text: "Spremni ste za prve klijente. Pratite zakazivanja i upravljajte terminima na jednostavan način.",
    },
    user: {
      title: "Vaša registracija je završena!",
      text: "Sada možete jednostavno zakazivati termine za željene usluge.",
    },
    button: "Zatvori",
  },
  profile: {
    image: "Slika profila",
    chooseImage: "Izaberi sliku za profil",
    form: {
      step1: "Lični podaci",
      step2: "Adresa",
      step3: "Posao",
    },
    labels: {
      firstName: "Ime",
      lastName: "Prezime",
      gender: "Pol",
      city: "Grad",
      street: "Ulica",
      postalCode: "Poštanski broj",
      companyName: "Ime firme",
      companyAddress: "Adresa firme",
      yearsInCompany: "Broj godina u firmi",
    },
  },
};
