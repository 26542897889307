export const secondaryThemeColors = {
  primaryDark: "#C4DFDF",
  secondaryDark: "#D2E9E9",
  primaryLighter: "#F8F6F4",
  secondaryLighter: "#E3F4F4",
  textColor: "#393646",
  backgroundColor: "#D1DBDB",
  secondaryColor: "#855CE0",
  purpleBoxBackgournd: "#FAF8FF",
  primaryColor: "#35C2C1",
  greenBoxBackground: "#F9FFFF",
  secondaryColorHover: "#8E76C2",
  primaryColorHover: "#66B5B4",
  completedRegistration: "#0A2323",
  uncompletedRegistration: "#658383",
  uncompletedRegistrationBackground: "#9BADAD",
  iconColor: "#FDFDFD",
  errorColor: "#FF4D4D",
};
