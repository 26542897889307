import i18n from "../i18nt/index";

// export const BASE_PAGE = "/";
// export const LOGIN_PAGE = "/login";
// export const REGISTER_PAGE = "/register";
// export const FORGOT_PASSWORD_PAGE = "/forgot-password";
// export const HOME_PAGE = "/";
// export const ERROR_PAGE = "/error-page";
// export const NOT_FOUND_PAGE = "/not-found";
export const AUTH_CALLBACK_PAGE = "/api/auth/:provider/callback";

export const PAGES = {
  BASE: {
    route: "/",
    hideHeader: true,
    title: i18n.t("pages.home"),
  },
  HOME: {
    route: "/home/*",
    title: i18n.t("pages.home"),
  },
  REGISTER_USER: {
    route: "/register/user",
    title: i18n.t("pages.register"),
    isAuth: true,
    nestedRoutes: {
      FIRST_STEP: {
        route: "/register/user/first-step",
        title: i18n.t("pages.register.nestedRoutes.firstStep"),
      },
      SECOND_STEP: {
        route: "/register/user/second-step",
      },
    },
  },
  REGISTER_CLIENT: {
    route: "/register/client",
    title: i18n.t("pages.register"),
    isAuth: true,
    nestedRoutes: {
      FIRST_STEP: {
        route: "/register/client/first-step",
        title: i18n.t("pages.register.nestedRoutes.firstStep"),
      },
      SECOND_STEP: {
        route: "/register/client/second-step",
      },
      THIRD_STEP: {
        route: "/register/client/third-step",
      },
    },
  },
  LOGIN: {
    route: "/login/*",
    title: i18n.t("pages.login"),
    isAuth: true,
  },
  FORGOT_PASSWORD: {
    route: "/forgot-password/*",
    title: i18n.t("pages.forgotPassword"),
  },
  ERROR: {
    route: "/error-page",
    title: i18n.t("pages.error"),
  },
  NOT_FOUND: {
    route: "/not-found",
    title: i18n.t("pages.notFound"),
  },
  PROFILE: {
    route: "/profile",
    title: i18n.t("pages.profile"),
  },
  SETTINGS: {
    route: "/settings",
    title: i18n.t("pages.settings"),
  },
  DASHBOARD: {
    route: "/dashboard",
    title: i18n.t("pages.dashboard"),
  },
  MIDDLEPAGE: {
    route: "/middlepage",
    title: i18n.t("pages.middlepage"),
    isAuth: true,
  },
};
