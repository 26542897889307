import React from "react";
import PropTypes from "prop-types";
import { InputFieldContainer, InputFieldsContainer, OrDividerGoogleBtnContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import FirstNameField from "components/RegistrationContent/InputFields/FirstNameField";
import LastNameField from "components/RegistrationContent/InputFields/LastNameField";
import { FormikParams } from "constants/formikParams"
import EmailField from "components/InputFields/EmailField";
import PasswordField from "components/InputFields/PasswordField";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { userColors } from "themes/primaryTheme/primaryThemeColors";
import { useFormik } from "formik";
import { UserStepOneAtributes } from "initialValues/userRegInitialValues.js";
import { UserStepOneValidation } from "validations/registerUserValidation";
import OrDivider from "components/OrDivider/OrDivider";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";

const UserStepOne = (props) => {
    const handleSubmit = async () => {
      console.log(formik.values);
      //postNewClientRequest();

      props.onClickNextPageBtn();
    }
    
    const handleGoogle = () => {
      
    }
  
    const formik = useFormik({
      initialValues: UserStepOneAtributes,
      validationSchema: UserStepOneValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <InputFieldContainer twoInputs>
                    <FirstNameField formik={formik} required/>
                    <LastNameField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer>
                    <EmailField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer twoInputs>
                    <PasswordField formik={formik} required/>
                    <PasswordField
                        label="common.labelPasswordConfirmation"
                        name={FormikParams.passwordConfirm}
                        placeholder="common.passwordConfirmationInput"
                        formik={formik}
                        required
                    />
                    </InputFieldContainer>
                </InputFieldsContainer>
                <OrDividerGoogleBtnContainer>
                    <OrDivider/>
                    <GoogleButton onClick={handleGoogle}/>
                </OrDividerGoogleBtnContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={userColors}
            />
        </>
    );
}

UserStepOne.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
};



export default UserStepOne;