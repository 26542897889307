import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import AuthType from "constants/authType";
import HomePage from "pages/HomePage/HomePage";
import RequireAuth from "components/RequireAuth/RequireAuth";
import AuthCallback from "pages/AuthCallbackPage/AuthCallbackPage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";
import DashboardPage from "pages/DashboardPage/DashboardPage";
import MiddlePage from "pages/MiddlePage/MiddlePage";
import RegisterPage from "pages/RegisterPage/RegisterPage";

const AppRoutes = () => (
  <Routes>
    <Route 
      path={PAGES.MIDDLEPAGE.route} 
      element={<MiddlePage />} 
      errorElement={<Error />}
    />
    <Route
      path={PAGES.LOGIN.route}
      element={<LoginPage />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.REGISTER_USER.route}
      element={<RegisterPage type={AuthType.USER} />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.REGISTER_CLIENT.route}
      element={<RegisterPage type={AuthType.CLIENT} />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.FORGOT_PASSWORD.route}
      element={<ForgotPasswordPage />}
      errorElement={<Error />}
    />
    <Route path="api/auth/:provider/callback" element={<AuthCallback />} />
    <Route element={<RequireAuth />} errorElement={<Error />}>
      <Route path={PAGES.HOME.route} element={<HomePage />} />
      <Route path={PAGES.BASE.route} element={<HomePage />} />
      <Route path={PAGES.PROFILE.route} element={<ProfilePage />} />
      <Route path={PAGES.SETTINGS.route} element={<SettingsPage />} />
      <Route path={PAGES.DASHBOARD.route} element={<DashboardPage />} />
      
    </Route>
  </Routes>
);

export default AppRoutes;
