import AddressField from "components/RegistrationContent/InputFields/AddressField";
import CityField from "components/RegistrationContent/InputFields/CityField";
import CompanyNameField from "components/RegistrationContent/InputFields/CompanyNameField";
import CountryField from "components/RegistrationContent/InputFields/CountryField";
import PhoneField from "components/RegistrationContent/InputFields/PhoneField";
import PrimaryServiceField from "components/RegistrationContent/InputFields/PrimaryServiceField";
import UsernameField from "components/RegistrationContent/InputFields/UsernameField";
import { InputFieldContainer, InputFieldsContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import React from "react";
import PropTypes from "prop-types";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { ClientStepOneAtributes } from "initialValues/clientRegInitialValues";
import { ClientStepOneValidation } from "validations/registerClientValidation";
import { clientColors } from "themes/primaryTheme/primaryThemeColors";

const ClientStepOne = (props) => {
    const handleSubmit = async () => {
      console.log(formik.values);
      //postNewClientRequest();

      props.onClickNextPageBtn();
    }
  
    const formik = useFormik({
      initialValues: ClientStepOneAtributes,
      validationSchema: ClientStepOneValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <InputFieldContainer twoInputs>
                    <UsernameField formik={formik} required/>
                    <CompanyNameField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer>
                    <PrimaryServiceField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer twoInputs>
                    <CountryField formik={formik} required/>
                    <CityField formik={formik} required/>
                    </InputFieldContainer>
                    <InputFieldContainer twoInputs>
                    <AddressField formik={formik} required/>
                    <PhoneField formik={formik} required/>
                    </InputFieldContainer>
                </InputFieldsContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={clientColors}
            />
        </>
    );
}

ClientStepOne.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
};



export default ClientStepOne;