import styled from 'styled-components';
import { ReactComponent as ClientImg } from "../../assets/images/svg/client-validation-message.svg";
import { ReactComponent as UserImg } from "../../assets/images/svg/user-validation-message.svg";
import AuthType from "constants/authType";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  position: relative;
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  max-width: 460px;
  aspect-ratio: 460 / 405;
  text-align: center;
  box-sizing: border-box;
`;

export const Divider = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid ${({ theme }) => theme.colors.lightPurpleColor};
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const Title = styled.h2`
  margin: 20px;
`;

export const Message = styled.p`
  margin-top: 40px;
  text-align: left;
`;

export const CloseButton = styled.button`
  background-color: ${({ type, theme }) => type === AuthType.CLIENT
        ? theme.colors.clientButtonColor
        : theme.colors.userButtonColor};
  color: ${({ type }) => type === AuthType.CLIENT ? "black"  : "white"};
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  width: 128px;
`;

const StyledImage = styled.img`
  width: 170px;
  height: 121px ;
  display: block;
  margin: 0 auto;
`;

export const StyledClientImg = styled(StyledImage).attrs({ as: ClientImg })``;
export const StyledUserImg = styled(StyledImage).attrs({ as: UserImg })``;