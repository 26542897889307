import { Button } from "@mui/material";
import React, { useState } from "react";
import AuthType from "constants/authType";
import ValidationMessage from "components/ValidationMessage/ValidationMessage";

const HomePage = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [authType, setAuthType] = useState(null);

  const handleOpenModal = (type) => {
    setAuthType(type);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button onClick={() => handleOpenModal(AuthType.USER)}>User Validate</Button>
      <Button onClick={() => handleOpenModal(AuthType.CLIENT)}>Client Validate</Button>

      {isModalVisible && <ValidationMessage onClose={handleCloseModal} type={authType} />}
    </>
  );
};

export default HomePage;