import React from "react";
import PropTypes from "prop-types";
import { InputFieldContainer, InputFieldsContainer, SentCodeBtnContainer, SentCodeMessageContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import SentCodeField from "components/RegistrationContent/InputFields/SentCodeField";
import { userColors } from "themes/primaryTheme/primaryThemeColors";
import { useTranslation } from "react-i18next";
import SendNewCodeButton from "components/RightAuthContent/Buttons/SendNewCodeButton/SendNewCodeButton";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { UserStepTwoAtributes } from "initialValues/userRegInitialValues.js";
import { UserStepTwoValidation } from "validations/registerUserValidation";

const UserStepTwo = (props) => {
    const { t } = useTranslation();

    const handleSubmit = async () => {
      console.log(formik.values);
      //postNewClientRequest();

      props.onClickNextPageBtn();
    }

    const onClickSendEmailCode = () => {
      console.log("send new email code");
    }
  
    const formik = useFormik({
      initialValues: UserStepTwoAtributes,
      validationSchema: UserStepTwoValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <SentCodeMessageContainer>
                    {t("code.sentEmailMessage")}
                    </SentCodeMessageContainer>
                    <InputFieldContainer>
                    <SentCodeField formik={formik} required/>
                    </InputFieldContainer>
                    <SentCodeBtnContainer>
                    <SendNewCodeButton
                        onClick={onClickSendEmailCode}
                        colorPalet={userColors}
                    />
                    </SentCodeBtnContainer>
                </InputFieldsContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={userColors}
            />
        </>
    );
}

UserStepTwo.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
    theme: PropTypes.any,
};

export default UserStepTwo;