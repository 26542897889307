import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomFields/CustomTextField";
import CustomFieldLabel from "./CustomFields/CustomFieldLabel";
import { ReactComponent as EyeOn } from '../../assets/images/svg/eye-on.svg';
import { ReactComponent as EyeOff } from '../../assets/images/svg/eye-off.svg';
import { FormikParams } from "constants/formikParams"
import TYPE from '../../constants/inputTypes';
import { Box } from "@mui/material";

const PasswordField = (props) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((oldValue) => !oldValue);
    const handleMouseDownPassword = () => setShowPassword((oldValue) => !oldValue);

    return (
        <Box>
            <CustomFieldLabel label={t(props.label)} required={props.required} />
            <CustomTextField
                type={showPassword ? "text" : "password"}
                name={props.name}
                placeholder={t(props.placeholder)}
                formik={props.formik}
                width={props.width}
                showEndIcon={showPassword}
                endIconClick={handleClickShowPassword}
                endIconMouseDown={handleMouseDownPassword}
                endIcon={showPassword ? <EyeOn /> : <EyeOff />}
            />
        </Box>
    )
}


PasswordField.propTypes = {
    label: PropTypes.bool,
    required: PropTypes.bool,
    name: PropTypes.bool,
    placeholder: PropTypes.bool,
    formik: PropTypes.any,
    width: PropTypes.string,
}

PasswordField.defaultProps = {
    label: "common.labelPassword",
    type: TYPE.PASSWORD,
    name: FormikParams.password,
    placeholder: "common.passwordInput",
}

export default PasswordField;
