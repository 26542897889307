export const primaryThemeColors = {
  primaryDark: "#393646",
  secondaryDark: "#4F4557",
  secondaryLighter: "#6D5D6E",
  primaryLighter: "#836E80",
  headerColor: "#FFFFFF",
  textColor: "#C4DFDF",
  backgroundColor: "#D1DBDB",
  purpleColor: "#855CE0",
  purpleBoxBackgournd: "#FAF8FF",
  greenColor: "#35C2C1",
  greenBoxBackground: "#F9FFFF",
  hoverPurpleColor: "#8E76C2",
  hoverGreenColor: "#66B5B4",
  completedRegistration: "#0A2323",
  uncompletedRegistration: "#658383",
  uncompletedRegistrationBackground: "#9BADAD",
  iconColor: "#FDFDFD",
  clientButtonColor: "#87CEEB",
  userButtonColor: "#173F61",
  lightPurpleColor: "#9966CC"
};

export const clientColors = {
  buttonColor: primaryThemeColors.purpleColor,
  buttonColorHover: primaryThemeColors.hoverPurpleColor,
};

export const userColors = {
  buttonColor: primaryThemeColors.greenColor,
  buttonColorHover: primaryThemeColors.hoverGreenColor,
};