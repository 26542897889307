export default {
  TEXT: "text",
  NUMBER: "number",
  PASSWORD: "password",
  EMAIL: "email",
  TEL: "tel",
  URL: "url",
  DATE: "date",
  DATETIME_LOCAL: "datetime-local",
  MONTH: "month",
  WEEK: "week",
  TIME: "time",
  SEARCH: "search",
  COLOR: "color",
};
