import React from "react";
import MiddlePageContent from "components/MiddlePage/MiddlePageContent";

const MiddlePage = () => {
  return (
    <>
    <MiddlePageContent />
    </>
  );
};

export default MiddlePage;
