import React from "react"
import { LogInButtonContainer } from "./LogInButton.styled"
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow.svg";
import { useTranslation } from "react-i18next";

export const LogInButton = () => {
    const { t } = useTranslation();
    return (
        <LogInButtonContainer
            type={"submit"}
            value={t("login.logIn")}
            endIcon={<RightArrow />}
            fullWidth
        />
    )
}