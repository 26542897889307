import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AuthType from "constants/authType";
import {
  Overlay,
  Divider,
  Modal,
  Title,
  Message,
  CloseButton,
  StyledUserImg,
  StyledClientImg,
} from "./ValidationMessage.styled";

const ValidationMessage = ({ type, onClose }) => {
  const { t } = useTranslation();
  const ImageComponent =
    type === AuthType.USER ? StyledUserImg : StyledClientImg;

  return (
    <Overlay>
      <Modal>
        <ImageComponent />
        <Title>{t(`validationmessage.${type}.title`)}</Title>
        <Message>{t(`validationmessage.${type}.text`)}</Message>
        <Divider>
          <CloseButton type={type} onClick={onClose}>
            {t(`validationmessage.button`)}
          </CloseButton>
        </Divider>
      </Modal>
    </Overlay>
  );
};

ValidationMessage.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ValidationMessage;
