import React from "react";
import AuthType from "constants/authType";
import AuthContent from "components/AuthContent/AuthContent";

 const LoginPage = () => {
  
  return (
      <AuthContent type={AuthType.LOGIN} />
  );
};

export default LoginPage;
