import React from "react";
import PropTypes from "prop-types";
import { InputFieldContainer, InputFieldsContainer, SentCodeBtnContainer, SentCodeMessageContainer, UpperContainer } from "components/RightAuthContent/RightAuthContent.styled";
import SentCodeField from "components/RegistrationContent/InputFields/SentCodeField";
import { clientColors } from "themes/primaryTheme/primaryThemeColors";
import { useTranslation } from "react-i18next";
import SendNewCodeButton from "components/RightAuthContent/Buttons/SendNewCodeButton/SendNewCodeButton";
import { useFormik } from "formik";
import { ClientStepThreeAtributes } from "initialValues/clientRegInitialValues";
import { ClientStepThreeValidation } from "validations/registerClientValidation";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";

const ClientStepThree = (props) => {
    const { t } = useTranslation();
    
    const handleSubmit = async () => {
        console.log(formik.values);
        //postNewClientRequest();
  
      }

    const onClickSendNewCode = () => {
        console.log("send new email to client");
    }

    const formik = useFormik({
        initialValues: ClientStepThreeAtributes,
        validationSchema: ClientStepThreeValidation,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        enableReinitialize: true,
    });
    
    return (
        <>
            <UpperContainer>
                <InputFieldsContainer>
                    <SentCodeMessageContainer>
                        {t("code.sentEmailMessage")}
                    </SentCodeMessageContainer>
                    <InputFieldContainer>
                    <SentCodeField formik={formik} required/>
                    </InputFieldContainer>
                    <SentCodeBtnContainer>
                    <SendNewCodeButton
                        onClick={onClickSendNewCode}
                        colorPalet={clientColors}
                    />
                    </SentCodeBtnContainer>
                </InputFieldsContainer>
            </UpperContainer>
            <LowerRegContent
                onClickNextBtn={formik.handleSubmit}
                onClickBackBtn={props.onClickBackBtn}
                currentStep={props.currentStep}
                colorPalet={clientColors}
            />
        </>
    );
}

ClientStepThree.propTypes = {
    onClickNextPageBtn: PropTypes.func,
    onClickBackBtn: PropTypes.func,
    currentStep: PropTypes.number,
};



export default ClientStepThree;