import React from "react";
import PropTypes from "prop-types";
import { HeaderContainer, HeaderSideContainer, Logo } from "./Header.styled";
import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";
import HeaderProfileBar from "./HeaderProfileBar/HeaderProfileBar";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";
import HeaderDrawer from "./HeaderDrawer/HeaderDrawer";
import { useLocation } from "react-router-dom";
import { getCurrentRoute } from "util/routeHelpers";

const Header = () => {
  const location = useLocation();
  const { isMobile } = useDevice();
  const currentRoute  = getCurrentRoute(location.pathname);

  return (
    <HeaderContainer>
      <HeaderSideContainer>
        <Logo to={PAGES.BASE.route} />
      </HeaderSideContainer>

      {!currentRoute.isAuth && (
        <HeaderSideContainer>
          {!isMobile && <HeaderNavigation />}
          {isMobile && <HeaderDrawer />}
          {!isMobile && <HeaderProfileBar />}
        </HeaderSideContainer>
      )}
    </HeaderContainer>
  );
};


Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
